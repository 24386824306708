<template>
    <div class="app-container" style="margin-bottom: 20px">
     <!-- -------万恶分割线------- -->
     <!-- 添加或编辑类别 -->
   
     <div style="margin-bottom: 20px">
       <el-row>
         <el-col :span="24" align="left">
           <el-button class="el-icon-edit" type="primary" @click="goback"
             >返回</el-button
           >
         </el-col>
        
       </el-row>
     </div>
       <!-- 表单 -->
       <el-form
         ref="perForm"
         :model="formData"
         :rules="rules"
         label-width="120px"
       >
      <el-row  >
         <el-form-item label="上传视频"   v-model="formData.fileList"
               prop="fileList"
               :rules="rules.fid">
           <el-upload
             class="upload-demo"
             ref="up"
             action="http://up-z0.qiniup.com"
             :on-preview="handlePreview"
             :show-file-list="true"
             accept=".mp4,.MP4"
             :limit="1"
             :on-success="handleAvatarSuccess0"
             :data="postData0"
             :before-upload="beforeAvatarUpload0"
             :on-progress="uploadVideoProcess"
           >
             <el-button type="primary">点击上传</el-button>
             <template #tip>
               <div class="el-upload__tip">只能上传mp4类型的文件</div>
             </template>
           </el-upload>
         </el-form-item>
 
         <el-form-item
           label="封面"
           v-model="formData.fileList"
         >
           <el-upload
             class="avatar-uploader"
             action="http://up-z0.qiniup.com"
             :show-file-list="false"
             accept="image/jpeg,image/gif,image/png"
             :on-success="handleAvatarSuccess1"
             :data="postData1"
             :before-upload="beforeAvatarUpload1"
         
           >
             <div v-if="img" class="avatar">
               <img :src="img" width="100%" />
             </div>
 
             <i v-else class="el-icon-plus avatar-uploader-icon"></i>
           </el-upload>
         </el-form-item>
      </el-row>
       </el-form>
       <el-row slot="footer" type="flex" justify="center">
         <el-col :span="6">
           <el-button size="small" type="primary" @click="btnOK">
             提交
           </el-button>
 
         </el-col>
       </el-row>
  
 
 
     
 
   
   </div>
 </template>
<script>
    import {
  addAlbumFile,
  getAlbumFileList,
  delAlbumFile,
  getUploadToken
} from '../../request/http'
export default {
    data() {
    return {
      editOradd:null,
      loadProgress:0,
      imgList: [], //多张照片回显
      fileList: [],
      list: '', //列表
      total: 0, //总数
      imageUrl: '',
      img: '',
      dialogVisible: false,
      postData: {
        token: '',
        key: '' //上传的Logo
      },

      postData0: {
        token: '',
        key: ''
      },
      postData1: {
        token: '',
        key: '' //上传的Logo
      },
      albumId: '',
      queryParams: {
        page: 1,
        pageSize: 10
      },
      title: '',
      showDialog: false,
    
      fileType:null,
      formData: {
        albumId: '',
        fileList: [
          {  fileType:2,
            fid:"",
            posterId:""

              }
        ]
      },
      rules: {
        // posterId: [
        //   { required: true, message: "封面不能为空", trigger: "blur" },
        // ],
        fileType: [
          { required: true, message: '上传类型不能为空', trigger: 'blur' }
        ],
        fid: [{ required: true, message: '文件不能为空', trigger: 'blur' }]
      }
    }
  },
  created() {
    this.albumId = this.$route.query.albumId
    this.formData.albumId = this.$route.query.albumId
    console.log(this.formData.albumId)
  },
methods: {
  uploadVideoProcess(event, file, fileList) {
          this.loadProgress = parseInt(event.percent); // 动态获取文件上传进度
          console.log(this.loadProgress);
      },

    handlePreview(file) {
      if (this.formData.fileType == 2 && this.formData.fid) {
        window.open('http://file.innopinenut.com/' + this.formData.fid, '_blank')
      } else {
        this.$message.warning('暂不支持预览')
      }
    },
     // 上传方法
     handleAvatarSuccess0(res, file) {
      console.log('上传成功', res, file)
      this.formData.fileList[0].fid = res.key
      this.loadProgress=this.loadProgress+1
      console.log(this.loadProgress);
    },
    async beforeAvatarUpload0(file) {
    
      let { data } = await getUploadToken()
      if (data == '' || data == null) {
        this.message.error('上传文件时获取Token失败！')
        return
      }
      this.postData0.token = data.token
      this.postData0.key = data.fid
    },

    // 上传图片方法
    handleAvatarSuccess1(res, file) {
      console.log('上传成功', res, file)
        this.img = 'http://file.innopinenut.com/' + res.key
        this.formData.fileList[0].posterId = res.key
      
    },
    async beforeAvatarUpload1(file) {
      // console.log("上传前");
      let { data } = await getUploadToken()
      // console.log(data);
      if (data == '' || data == null) {
        this.message.error('上传时获取Token失败！')
        return
      }
      this.postData1.token = data.token
      this.postData1.key = data.fid
    },
     // 返回上一级页面
     goback() {
      this.$router.go(-1)
    },
    // 确认
    async btnOK() {
      if( this.loadProgress==101){
        this.$refs.perForm.validate().then(async () => {
      
      
      let { code, msg } = await addAlbumFile(this.formData)
      console.log(code, msg)
      if (code == 0) {
        this.$message.success('新增成功')
      } else {
        console.log(msg)
        this.$message({
          type: 'error',
          message: msg
        })
      }
      this.goback()
     
    })
      } else if (this.loadProgress<=100){
        this.$message({
          type: 'error',
          message: "请等待视频上传完成"
        })
      }
      
    },

},
}
</script>

<style>

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>